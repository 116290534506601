import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'
import SEO from '../components/seo'
import BlogPostItem from '../components/blog-post-item'

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
`

const Heading = styled.div`
  background: #fff;

  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const BlogPage = props => (
  <StaticQuery
    query={graphql`
      query GetAllBlogPosts {
        allContentfulBlogPost(sort: { fields: publishDate, order: DESC }) {
          edges {
            node {
              id
              title
              slug
              metaDescription
              author {
                name
              }
              category {
                name
                slug
              }
              publishDate(formatString: "MMMM Do, YYYY")
              tags
              heroImage {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Layout pageProps={props}>
            <SEO
              title={
                'Solid Construction & Design Blog - Home Remodeling Tips & Tricks'
              }
              description={
                'Solid Construction & Design Blog Offers Industry News, Home Improvement Tips, Ideas, Insights and More!'
              }
            />
            <Heading>
              <h1>Solid Construction &amp; Design Blog</h1>
              <p>Home Renovation Industry News, Tips &amp; Ideas</p>
            </Heading>
            <BlogPostsLayoutContainer className="container">
              {data.allContentfulBlogPost.edges.map((item, i) => (
                <BlogPostItem
                  key={i}
                  title={item.node.title}
                  slug={item.node.slug}
                  image={item.node.heroImage.gatsbyImageData}
                  publishDate={item.node.publishDate}
                  category={item.node.category.name}
                  categorySlug={item.node.category.slug}
                  author={item.node.author.name}
                  excerpt={item.node.metaDescription}
                />
              ))}
            </BlogPostsLayoutContainer>
          </Layout>
        </>
      )
    }}
  />
)

export default BlogPage
